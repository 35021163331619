import React, { useEffect } from "react"
import { useNavigate } from "@reach/router"

const IndexPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    console.log("useEffect")
    navigate("/de").then(() => console.log("route changed"))
  }, [])
  return(<div/>)
}

export default IndexPage
